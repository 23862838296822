import React from 'react';

import { Page } from '@tager/web-components';
import { convertSeoParamsToPageProps } from '@tager/web-modules';

import NotFoundPage from '@/pages/404';
import { CustomApp_PageContext } from '@/typings/hocs';
import {
  getPageByPathThunk,
  getPageListThunk,
} from '@/store/reducers/tager/pages';
import { getPageModuleByTemplate } from '@/services/pageModules';
import { getCurrentPath } from '@/utils/common';
import useLayoutPage from '@/hooks/useLayoutPage';

type Props = {
  pageType: 'DYNAMIC_PAGE' | 'NOT_FOUND';
  template?: string;
};

function DynamicPage(props: Props) {
  const page = useLayoutPage();

  if (props.pageType === 'NOT_FOUND') {
    return <NotFoundPage />;
  }

  const foundPageModule = getPageModuleByTemplate(props.template);

  const pageElement = React.createElement(foundPageModule.component);

  const seoPageProps = convertSeoParamsToPageProps(page?.seoParams);

  if (seoPageProps.openGraphImage === null && page?.image) {
    seoPageProps.openGraphImage = page.image.url;
  }

  return <Page {...seoPageProps}>{pageElement}</Page>;
}

DynamicPage.getInitialProps = async (
  context: CustomApp_PageContext
): Promise<Props> => {
  const { store, query, pathname, res } = context;

  const currentPath = getCurrentPath(pathname, query.slug);

  try {
    const pageList = await store.dispatch(getPageListThunk());

    const foundPage = pageList.find((page) => page.path === currentPath);

    const foundPageModule = getPageModuleByTemplate(foundPage?.template);

    if (!foundPage) {
      if (context.res) {
        context.res.statusCode = 404;
      }

      return { pageType: 'NOT_FOUND' };
    }

    const templateData = await store.dispatch(
      getPageByPathThunk(foundPage.path)
    );

    await Promise.all([
      foundPageModule.getInitialProps
        ? foundPageModule.getInitialProps(context, templateData)
        : Promise.resolve(),
    ]);

    return {
      pageType: 'DYNAMIC_PAGE',
      template: foundPageModule.template,
    };
  } catch (error: any) {
    if (error.status.code === 404) {
      if (res) {
        res.statusCode = 404;
      }

      return {
        pageType: 'NOT_FOUND',
      };
    }

    return {
      pageType: 'DYNAMIC_PAGE',
      template:
        store.getState().tager.pages.pageFullMap?.[currentPath]?.template ?? '',
    };
  }
};

export default DynamicPage;
